import React, { useState } from 'react';
import {Button, message, Modal} from 'antd';
import VacanciesCreateForm from "./VacanciesCreateForm.tsx";
import styles from './vacancies.module.css'
import {VacancyApiFactory, VacancyDtoRequest, VacancyDtoResponse} from '../../generated/backend';
import {useForm} from "antd/es/form/Form";

const VacanciesModal: React.FC<{updateVacancies: React.Dispatch<React.SetStateAction<VacancyDtoResponse[]>>}> = ({updateVacancies}) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = useForm();
    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async (formData: VacancyDtoRequest) => {
        try {
            setConfirmLoading(true);
            const response = await VacancyApiFactory().apiVacancyPost(formData)
            message?.success(`Вакансия успешно создана "${response.data.name}"`)
            console.log('Вакансия успешно создана', response.data)
            updateVacancies((prevVacancies: VacancyDtoResponse[]) => [...prevVacancies, response.data]);
            setTimeout(() => {
                setOpen(false);
                setConfirmLoading(false);
                form.resetFields();
            }, 2000);
        } catch (error){
            console.error('Ошибка при создании вакансии:', error);
            message.error('Ошибка при создании вакансии');
            setConfirmLoading(false);
        }
    };
    const handleModalOk = () => {handleOk({})};

    const handleCancel = () => {
        setOpen(false);
        form.resetFields();
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Создать вакансию
            </Button>
            <Modal
                className={styles.modal}
                open={open}
                onOk={handleModalOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                width={650}
                okText="Создать"
                cancelText="Отмена"
                styles={{ body: { overflow: 'auto', maxHeight: 'calc(100vh - 300px)' } }}
            >
                <h2 className={styles.vacancyFormTitle}>Новая вакансия</h2>
                <VacanciesCreateForm onSubmit={handleModalOk} form={form}/>
            </Modal>
        </>
    );
};

export default VacanciesModal
