import {FC, useEffect, useState} from "react";
import {Button, Form, Input, message, Modal, Space, Table} from "antd";
import styles from '../components/vacancies/vacancies.module.css'
import {useLocation} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {CandidateDtoResponse, CandidatesApiFactory, CandidateDtoRequest} from "../generated/backend";
import {useForm} from "antd/es/form/Form";

const VacancyPage: FC = () =>{
    const location = useLocation();
    const vacancyId = location.state?.vacancyId;
    const vacancyName = location.state?.vacancyName;
    const [openCandidateAddModal, setOpenCandidateAddModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [vacancyCandidates, setVacancyCandidates] = useState<CandidateDtoResponse[]>([])
    const [form] = useForm()
    const showCandidateAddModal = () => {
        setOpenCandidateAddModal(true);
    };

    const handleShowCandidateAddOk = async () => {
        try {
            setConfirmLoading(true);
            const formData = form.getFieldsValue() as CandidateDtoRequest

            const response = await CandidatesApiFactory().apiCandidatePost(formData)
            console.log('Кандидат успешно создан:', response.data);
            message?.success(`Кандидат ${response.data.name} успешно создан`)
            setVacancyCandidates(prevCandidates => [...prevCandidates, response.data]);
            setTimeout(() => {
                setOpenCandidateAddModal(false);
                setConfirmLoading(false);
                form.resetFields();
            }, 1000);
        } catch (error){
            console.error('Ошибка при создании кандидата:', error);
            message.error('Ошибка при создании кандидата');
            setConfirmLoading(false);
        }
    };
    const handleShowCandidateAddCancel = () => {
        setOpenCandidateAddModal(false);
        form.resetFields();
    };

    useEffect(() => {
        const fetchVacancyCandidates = async () =>{
            try {
                const response = await CandidatesApiFactory().apiVacancyIdCandidateGet(vacancyId)
                setVacancyCandidates(response.data)
            } catch (error){
                console.error('Ошибка при загрузке вакансий', error)
            }
        }
        fetchVacancyCandidates()
    }, []);
    const columns: ColumnsType<CandidateDtoResponse> = [
        {
            key: 'name',
            title: 'Кандидаты',
            dataIndex: 'name',
        },
        {
            key: 'notes',
            title: 'Заметки',
            dataIndex: 'notes',
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
        },
        {
            key: 'phoneNumber',
            title: 'Номер телефона',
            dataIndex: 'phoneNumber',
        },
        {
            key: 'telegram',
            title: 'Telegram',
            dataIndex: 'telegram',
        },
        {
            key: 'citizenship',
            title: 'Гражданство',
            dataIndex: 'citizenship',
        },
    ]
    return(
        <div>
            <Space className={styles.vacanciesTitle}>
                <h2>{vacancyName}</h2>
                <div>
                    <Button type="primary" onClick={showCandidateAddModal}>
                        Добавить кандидата
                    </Button>
                    <Modal
                        className={styles.modal}
                        open={openCandidateAddModal}
                        onOk={handleShowCandidateAddOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleShowCandidateAddCancel}
                        width={650}
                        okText="Добавить"
                        cancelText="Отмена"
                        styles={{ body: { overflow: 'auto', maxHeight: 'calc(100vh - 300px)' } }}
                    >
                        <h2>Добавить кандидата</h2>
                        <Form
                            form={form}
                            name='changeVacancy'
                            labelCol={{span: 8}}
                            wrapperCol={{span: 14}}
                            style={{maxWidth: 650}}
                            autoComplete="off"
                            // initialValues={{...selectedRecord}}
                            onFinish={handleShowCandidateAddOk}
                        >
                            <Form.Item<CandidateDtoRequest>
                                label="Имя кандидата"
                                name="name"
                                rules={[{required: true, message: 'Обязательное поле'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item<CandidateDtoRequest>
                                label="Заметки"
                                name="notes"
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item<CandidateDtoRequest>
                                label="Email"
                                name="email"
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item<CandidateDtoRequest>
                                label="Номер телефона"
                                name="phoneNumber"
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item<CandidateDtoRequest>
                                label="Telegram"
                                name="telegram"
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item<CandidateDtoRequest>
                                label="Гражданство"
                                name="citizenship"
                            >
                                <Input/>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Space>
            <Table<CandidateDtoResponse>
                columns={columns}
                dataSource={vacancyCandidates}
                pagination={{pageSize: 7}}
                rowKey='id'
                rowSelection={{}}
            />
        </div>
    )
}
export default VacancyPage