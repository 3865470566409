import './App.css'
import Layout from "./components/layout";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import VacanciesPage from "./pages/VacanciesPage.tsx";
import CandidatesPage from "./pages/CandidatesPage.tsx";
import CalendarPage from "./pages/CalendarPage.tsx";
import SettingsPage from "./pages/SettingsPage.tsx";
import WelcomePage from "./pages/WelcomePage.tsx";
import VacancyPage from "./pages/VacancyPage.tsx";
function App() {

  return (
      <div>
          <Router>
              <Routes>
                  <Route path="/" element={<Layout />}>
                      <Route index element={<WelcomePage />} />
                      <Route path="/vacancies" element={<VacanciesPage />} />
                      <Route path="/candidates" element={<CandidatesPage />} />
                      <Route path="/calendar" element={<CalendarPage/>} />
                      <Route path="/settings" element={<SettingsPage/>} />
                      <Route path="/vacancies/:id" element={<VacancyPage/>} />
                  </Route>
              </Routes>
          </Router>
      </div>
  )
}

export default App
