import React, { useState } from 'react';
import { Modal, message, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { VacancyDtoResponse, VacancyApiFactory } from '../../generated/backend';

interface VacanciesDeleteProps {
    record: VacancyDtoResponse;
    onDelete: (record: VacancyDtoResponse) => void;
}

const VacanciesDelete: React.FC<VacanciesDeleteProps> = ({ record, onDelete }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleDeleteOk = async () => {
        try {
            const response = await VacancyApiFactory().apiVacancyIdDelete(record?.id ?? 0);
            if (response.status === 200) {
                console.log(`Вакансия "${record?.name}" успешно удалена`);
                message.success(`Вакансия "${record?.name}" успешно удалена`);
                onDelete(record);
            } else {
                console.log('Ошибка при удалении вакансии', response);
                message.error(`Ошибка при удалении вакансии "${record?.name}"`);
            }
        } catch (error) {
            console.error('Ошибка при удалении вакансии', error);
            message.error('Ошибка при удалении вакансии');
        } finally {
            setOpenDeleteModal(false);
        }
    };

    const handleDeleteCancel = () => setOpenDeleteModal(false);

    return (
        <>
            <Button
                onClick={() => setOpenDeleteModal(true)}
                type="link"
                icon={<DeleteOutlined />}
            />
            <Modal
                title="Удаление вакансии"
                open={openDeleteModal}
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}
                okText="Удалить"
                okType="danger"
                cancelText="Отмена"
            >
                <p>{`Вы уверены, что хотите удалить вакансию "${record?.name}"?`}</p>
            </Modal>
        </>
    );
};

export default VacanciesDelete;
