import { useLocation, useNavigate } from 'react-router-dom';
import Home from '../../../assets/home.svg';
import { LoadingOutlined } from '@ant-design/icons';

const Breadcrumbs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const paths = location.pathname.split('/').filter((path) => path !== '');
    return (
        <>
            <li onClick={() => navigate('/')}>
                {/*<img src={Home} alt="HomeIcon"/>*/}
              <Home />
            </li>
            {paths.map((path, index) => {
                const routeTo = paths.slice(0, index + 1).join('/');
                const isLast = index === paths.length - 1;
                return isLast ? (
                    <li key={index}>{path || <LoadingOutlined rev={undefined} />}</li>
                ) : (
                    <li
                        onClick={() => {
                            navigate(`/${routeTo}`);
                        }}
                        key={index}
                    >
                        {path || <LoadingOutlined rev={undefined} />}
                    </li>
                );
            })}
        </>
    );
};

export default Breadcrumbs;
