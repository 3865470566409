import React, { useState, useEffect } from 'react';
import {Table, Space, message, Button, Modal, Form, Select, Input} from 'antd';
import VacanciesModal from "../components/vacancies/VacanciesModal.tsx";
import styles from '../components/vacancies/vacancies.module.css';
import {VacancyApiFactory, VacancyDtoRequest, VacancyDtoResponse} from '../generated/backend';
import VacanciesDelete from '../components/vacancies/VacanciesDelete';
import {useForm} from "antd/es/form/Form";
import {FormOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const VacanciesPage: React.FC = () => {
    const [vacancies, setVacancies] = useState<VacancyDtoResponse[]>([]);
    const [openChangeModal, setOpenChangeModal] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState<VacancyDtoResponse | null>(null);
    const [form] = useForm()
    const showChangeModal = (record: VacancyDtoResponse) =>{
        setSelectedRecord(record)
        form.setFieldsValue(record);
        setOpenChangeModal(true)
    }
    const handleChangeOk = async () => {
        try {
            const response = await VacancyApiFactory().apiVacancyIdPut(
                selectedRecord?.id ?? 0,
                form.getFieldsValue()
            );
            if (response.status === 200) {
                console.log(`Вакансия "${selectedRecord?.name}" успешно изменена`, response.data);
                message.success(`Вакансия "${selectedRecord?.name}" успешно изменена`);
                setOpenChangeModal(false);
                const updatedVacancies = vacancies.map((vacancy) =>
                    vacancy.id === selectedRecord?.id ? response.data : vacancy
                );
                setVacancies(updatedVacancies);
            } else {
                console.log('Ошибка при изменении вакансии', response);
                message.error(`Ошибка при изменении вакансии "${selectedRecord?.name}"`);
            }
        } catch (error){
            console.error('Ошибка при изменении вакансии', error);
            message.error('Ошибка при изменении вакансии');
        } finally {
            setSelectedRecord(null);
        }
    }
    const handleChangeCancel = () => setOpenChangeModal(false);

    const handleDelete = (deletedRecord: VacancyDtoResponse) => {
        const updatedVacancies = vacancies.filter(vacancy => vacancy.id !== deletedRecord?.id);
        setVacancies(updatedVacancies);
    };

    useEffect(() => {
        const fetchVacancies = async () => {
            try {
                const response = await VacancyApiFactory().apiVacancyGet();
                if (response.data && Array.isArray(response.data)) {
                    setVacancies(response.data);
                } else {
                    console.error('Некорректный формат данных, ожидался массив', response.data);
                }
            } catch (error) {
                console.error('Ошибка при загрузке вакансий', error);
            }
        };
        fetchVacancies();
    }, []);

    const columns = [
        {
            key: 'name',
            title: 'Вакансия',
            dataIndex: 'name',
            render: (text: string, record: VacancyDtoResponse) => (
                <div className={styles.vacanciesName}>
                    <Link to={`/vacancies/${record.id}`} state={{ vacancyId: record.id, vacancyName: record.name }}>
                        <div className={styles.vacanciesNameText}>{text}</div>
                    </Link>
                    <Space>
                        <Button
                            onClick={() => showChangeModal(record)}
                            type='link'
                            icon={<FormOutlined />}
                        />
                        <VacanciesDelete record={record} onDelete={handleDelete} />
                    </Space>
                </div>
            )
        },
        {
            key: 'department',
            title: 'Отдел',
            dataIndex: 'department',
        },
        {
            key: 'city',
            title: 'Город',
            dataIndex: 'city',
        },
        {
            key: 'testTask',
            title: 'Тестовое задание',
            dataIndex: 'testTask',
        },
        {
            key: 'salary',
            title: 'Зарплата',
            dataIndex: 'salary',
        },
    ];

    return (
        <div>
            <Space className={styles.vacanciesTitle}>
                <h2>Вакансии</h2>
                <div><VacanciesModal updateVacancies={setVacancies} /></div>
            </Space>
            <Table
                columns={columns}
                dataSource={vacancies}
                pagination={{ pageSize: 7 }}
                rowKey='id'
            />
            <Modal
                title="Изменение вакансии"
                open={openChangeModal}
                onOk={handleChangeOk}
                onCancel={handleChangeCancel}
                okText="Сохранить"
                cancelText="Отмена"
            >
                <Form
                    form={form}
                    name='changeVacancy'
                    labelCol={{span: 8}}
                    wrapperCol={{span: 14}}
                    style={{maxWidth: 650}}
                    autoComplete="off"
                    initialValues={{...selectedRecord}}
                    onFinish={handleChangeOk}
                >
                    <Form.Item<VacancyDtoRequest>
                        label="Название вакансии"
                        name="name"
                        rules={[{required: true, message: 'Обязательное поле'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Статус"
                        name="status"
                    >
                        <Select>
                            <Select.Option value="В работе">В работе</Select.Option>
                            <Select.Option value="На паузе">На паузе</Select.Option>
                            <Select.Option value="В архиве">В архиве</Select.Option>
                            <Select.Option value="Неактуальна">Неактуальна</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Отдел"
                        name="department"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Город"
                        name="city"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Зарплата"
                        name="salary"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Примечания"
                        name="notes"
                    >
                        <Input.TextArea rows={3}/>
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Описание"
                        name="description"
                    >
                        <Input.TextArea rows={3}/>
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Тестовое задание"
                        name="testTask"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Размещен"
                        name="placed"
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default VacanciesPage;