import React, {useEffect} from 'react';
import {Form, FormInstance, Input, Select} from 'antd';
import {VacancyDtoRequest, VacancyDtoResponse} from "../../generated/backend";

interface VacanciesCreateFormProps {
    onSubmit: (data: VacancyDtoRequest) => void;
    initialValues?: VacancyDtoResponse | null;
    form: FormInstance<VacancyDtoRequest>;
}
const VacanciesCreateForm: React.FC<VacanciesCreateFormProps> = ({form, onSubmit, initialValues}) => {
    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        onSubmit(formData);
    };
    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [form, initialValues]);
    return(
        <div>
            <h3>Основная информация:</h3>
            <Form
                form={form}
                name='createVacancy'
                labelCol={{span: 8}}
                wrapperCol={{span: 14}}
                style={{maxWidth: 650}}
                autoComplete="off"
                initialValues={{vacancyStatus: 'В работе'}}
                onFinish={handleSubmit}
            >
                <Form.Item<VacancyDtoRequest>
                    label="Название вакансии"
                    name="name"
                    rules={[{required: true, message: 'Обязательное поле'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Статус"
                    name="status"
                >
                    <Select>
                        <Select.Option value="В работе">В работе</Select.Option>
                        <Select.Option value="На паузе">На паузе</Select.Option>
                        <Select.Option value="В архиве">В архиве</Select.Option>
                        <Select.Option value="Неактуальна">Неактуальна</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Отдел"
                    name="department"
                >
                    <Input/>
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Город"
                    name="city"
                >
                    <Input/>
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Зарплата"
                    name="salary"
                >
                    <Input/>
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Примечания"
                    name="notes"
                >
                    <Input.TextArea rows={3}/>
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Описание"
                    name="description"
                >
                    <Input.TextArea rows={3}/>
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Тестовое задание"
                    name="testTask"
                >
                    <Input/>
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Размещен"
                    name="placed"
                >
                    <Input/>
                </Form.Item>
            </Form>
        </div>
    )
};

export default VacanciesCreateForm
